import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import SplitSection from '../components/SplitSection';
import Image from '../images/4041.png';

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404 Not Found" />
      <SplitSection
        id="404"
        reverseOrder
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-6xl font-semibold leading-tight">404</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              The page you are looking for is not here. <br />
              Try again or go to our <Link to="/">homepage</Link>. .{' '}
            </p>
          </div>
        }
        secondarySlot={<img classname="" src={Image} alt="" />}
      />
    </Layout>
  );
};

export default NotFound;
